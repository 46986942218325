<template>
  <div>
    <div v-if="entriesChecked.length > 1 && (this.currentActivity.reason === undefined && !reseted)" class="info-container">
      <p class="text-reset">{{ $t("activities.multiple.edit.reason") }}</p>
      <RewriteButton :on-click="resetAllReasons"/>
    </div>
    <!-- Reasons -->
    <div v-else>
      <Dropdown
      ref="dropdown"
      class="shape-custom-select"
      label="designation"
      :value="designation"
      :options="reasonsUpdated"
      @input="(selectedReason) => addReasons(selectedReason.id)"
      :clearSelection="true"
    />
    </div>
  </div>
</template>

<script>
import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import RewriteButton from "@/components/commons/RewriteButton.vue";

export default {
  name: "edit-reasons-activity",
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
    inputFormPath: {
      type: String
    }
  },
  data() {
    return {
      reasonSelected: "",
      reasonsUpdated: [],
      selectedInputs: [],
      inputsStock: [],
      designation: {},
      loadComponent: false,
      reseted: false,
    };
  },
  components: {
    Dropdown,
    RewriteButton
  },
  mounted() {
    this.init();
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
    }
  },
  methods: {
    /**
     * @description initialisation du motif à partir de l'activité actuelle
     */
    init: async function () {
      this.loadComponent = false;
      if (Object.keys(this.currentEntry) == 0 && Object.keys(this.currentEditedEntry) == 0) {
        this.loadComponent = true;
        return;
      }
      if (["activity.edit", "tour.edit", "tour.duplicate"].includes(this.$route.name)) {
        if (this.$route.name === "activity.edit") {
        }
        this.getReasonsUpdated();
      }
      this.loadComponent = true
    },
    /**
    * @description retourne le tableau des motifs possibles en fonction de la famille des produits sélectionnés
    * @returns {array}
    */
    getReasonsUpdated() {
      if (this.inputForm?.inputsStock != undefined) {
        this.selectedInputs = this.currentEditedEntry.inputs;
        this.inputsStock = [];
        if(this.selectedInputs)
          this.selectedInputs.map((selectedInput) => {
            this.inputsStock.push(selectedInput.inputsStock);
          });
        this.reasonsUpdated = [];
        for (let i = 0; i < this.inputsStock.length; i++) {
          this.inputForm.inputsStock.map((inputsStock) => {
            if (this.inputsStock[i] === inputsStock.id) {
              let productFamily = inputsStock.productFamily_id;
              this.inputForm.reasons.map((reason) => {
                if (reason.productFamily_id === productFamily) {
                  if(!this.isRepeatedReason(reason))
                    this.reasonsUpdated.push(reason);
                }
              });
            }
          });
        }
      }

      // Actualisation du motif sélectionné dans le formulaire
      let designation = {};
      const entryReason = this.currentEditedEntry.reason || this.currentEntry.reason

      if (entryReason) {
        this.reasonsUpdated.map(element => {
          if(element.id == entryReason){
            this.reasonSelected = entryReason;
            this.getReasonDesignation(entryReason);
            this.$refs["dropdown"].setValue(designation)
          }
        });
      }
      return;
    },
    /**
     * @description Retrouve l'intitulé du motif en fontion de son id
     * @param {number} id l'id du motif
     */
    getReasonDesignation(id) {
      this.designation = this.inputForm?.reasons.find((reason) => reason.id === id);
    },
    /**
     * @description Met à jour le motif de l'activité
     * @param {number} value l'id du motif à ajouter
     */
    addReasons(value) {
      this.reasonSelected = value;
      this.getReasonDesignation(value);
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
    /**
     * @description Vérifie si l'activité actuelle a au moins un motif applicable
     * @returns {boolean}
     */
    needReasons() {
    if(this.reasonsUpdated.length != 0)
      return true;
    return false;
    },
    /**
     * @description Verifie si un motif appartient déja au tableau des motifs possibles de l'activité
     * @returns {boolean}
     */
    isRepeatedReason(reason) {
      let repeatedReasons = 0;
      this.reasonsUpdated.map((e) => {
        if (e.designation === reason.designation) repeatedReasons++;
      });
      if (repeatedReasons === 0) return false;
      return true;
    },
    resetAllReasons(){
      this.reseted = true
      this.$store.dispatch(this.storeEditAction, { reason: null})
    }
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      activityTypes: "activityTypes",
      currentActivityType: "activityTypes",
      entriesChecked: "activity/entriesChecked"
    }),
    inputForm() {
      return this.$store.getters[this.inputFormPath]
    },
    hideComponent() {
      return this.reasonsUpdated.length == 0
    }
  },
  watch: {
    reasonSelected: {
      handler: function () {
        this.$store.dispatch(this.storeEditAction, { reason: this.reasonSelected });
      },
    },
    currentEditedEntry: {
      deep: true,
      handler: function () {
        this.selectedInputs = this.currentEditedEntry.inputs;
      },
    },
    selectedInputs: {
      handler: function () {
        this.getReasonsUpdated();
        if(Object.keys(this.designation).length != 0 && !this.isRepeatedReason(this.designation)){
          this.reasonSelected = null;
          this.designation = {};
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 3px 3px;
  margin-bottom: 4px;
}

button {
  //   background-color: $danger-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}

.text-reset {
  font-size: 14px;
  padding: 5px;
  color: #222e50;
  margin: 5px;
}

/**
 * Parameter error quantity inputs.
 */
.error-quantity-inputs {
  // text-align: center;
  color: $danger-color;
}
</style>
